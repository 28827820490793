import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Typography, Container, styled, useTheme } from "@mui/material";
import logoLight from "../images/lantern-logo-light.svg";
import logoDark from "../images/lantern-logo-dark.svg";

const TitleLink = styled(RouterLink)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: theme.palette.primary.contrastText,
  textDecoration: "none",
  "& img": {
    top: 2,
    height: 48,
    marginRight: theme.spacing(1)
  }
}));

const AppHeader = () => {
  const {
    palette: { mode }
  } = useTheme();
  return (
    <AppBar position="static" color="inherit" elevation={6}>
      <Toolbar sx={{ pl: 0 }}>
        <Container maxWidth="lg">
          <TitleLink to="/">
            <img src={mode === "light" ? logoLight : logoDark} alt="Lantern logo" />
            <Typography variant="body1" sx={{ color: mode === "light" ? "offCommon.black" : "white", fontSize: 20, fontWeight: 400 }}>
              SecretShare
            </Typography>
          </TitleLink>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
