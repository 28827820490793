import { createTheme, responsiveFontSizes } from "@mui/material";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";

declare module "@mui/material/styles" {
  interface Palette {
    offCommon: Palette["common"];
  }

  interface PaletteOptions {
    offCommon?: PaletteOptions["common"];
  }
}

export const GetAppTheme = (options: { mode: "dark" | "light" }) =>
  responsiveFontSizes(
    createTheme({
      components: {
        MuiPaper: {
          defaultProps: {
            elevation: 6
          }
        },
        MuiTabs: {
          defaultProps: {
            textColor: "inherit"
          },
          styleOverrides: {
            indicator: ({ theme }) => ({
              backgroundColor: theme.palette.secondary.dark
            })
          }
        },
        MuiTab: {
          styleOverrides: {
            root: ({ theme }) => ({
              opacity: 1,
              fontWeight: 500,
              textTransform: "none",
              color: options.mode === "light" ? theme.palette.primary.dark : "inherit",
              "&.Mui-selected": {
                fontWeight: 600
              }
            })
          }
        },
        MuiAlert: {
          styleOverrides: {
            standardInfo: {
              backgroundColor: options.mode === "light" ? "#E5F6FD" : "#2CD1E826",
              border: `1px solid ${options.mode === "light" ? "#E5F6FD" : "#2CD1E8"}`,
              color: options.mode === "light" ? "#014361" : "#FFF"
            }
          }
        }
      },
      typography: {
        fontFamily: "Montserrat",
        button: {
          textTransform: "none"
        }
      },
      palette: {
        mode: options.mode,
        background: {
          ...(options.mode === "light"
            ? {
                default: "#efefef"
              }
            : { default: "#1e1e1e" })
        },
        primary: { main: "#0d9b8c", dark: "#00675b", light: "#00d6ba" },
        secondary: { main: "#ffae00", dark: "#ff7f02", contrastText: "#FFF" },
        offCommon: {
          white: "#efefef",
          black: "#1e1e1e"
        },
        error: { main: "#d32f2f" }
      }
    })
  );
