import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, StyledEngineProvider, useMediaQuery } from "@mui/material";
import Layout from "./components/Layout";
import Home from "./components/Home";
import { GetAppTheme } from "./Theme";
import Secret from "./components/Secret";
import Created from "./components/Created";

const App = () => {
  const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
  console.log(theme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={GetAppTheme({ mode: theme })}>
        <CssBaseline />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/created/:id" element={<Created />} />
            <Route path="/secured/:id" element={<Secret />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
