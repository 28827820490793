import React from "react";
import { Card, CardContent, Container } from "@mui/material";
import AppHeader from "./AppHeader";

const Layout = (props: { children: JSX.Element }) => {
  return (
    <div>
      <AppHeader />
      <Container sx={{ mt: 4 }}>
        <Card>
          <CardContent>{props.children}</CardContent>
        </Card>
      </Container>
    </div>
  );
};
export default Layout;
