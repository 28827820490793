import React from "react";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useFormik } from "formik";
import { PersistSecretRequest } from "../api/models";

export type PersistSecretRequestErrorDictionary = { [key in keyof PersistSecretRequest]: string };

export const validatePersistSecretRequest = (value: PersistSecretRequest): PersistSecretRequestErrorDictionary => {
  const errors = {} as PersistSecretRequestErrorDictionary;
  const requiredFields: (keyof PersistSecretRequest)[] = ["secret", "passphrase"];
  for (const field of requiredFields) {
    if (!value[field]) {
      errors[field] = "Required";
    }
  }
  if ((value.secret?.length || 0) > 2000) {
    errors["secret"] = "Too long";
  }
  if ((value.passphrase?.length || 0) > 2000) {
    errors["passphrase"] = "Too long";
  }
  return errors;
};

const CreateSecretForm = (props: { saveSecret: (value: PersistSecretRequest) => any }) => {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      secret: "",
      passphrase: "",
      expiryInMinutes: 30
    } as PersistSecretRequest,
    onSubmit: async (value, helpers) => {
      setIsSaving(true);
      await props.saveSecret(value);
      setIsSaving(false);
    },
    validate: validatePersistSecretRequest
  });

  return (
    <form
      onSubmit={(e) => {
        setSubmitAttempted(true);
        formik.handleSubmit(e);
      }}
      autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            name="secret"
            label="Secret"
            spellCheck={false}
            multiline
            value={formik.values.secret}
            error={submitAttempted && !!formik.errors.secret}
            onChange={formik.handleChange}
            fullWidth
            helperText={submitAttempted ? formik.errors.secret : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <TextField
            variant="outlined"
            name="passphrase"
            label="Passphrase"
            spellCheck={false}
            value={formik.values.passphrase}
            error={submitAttempted && !!formik.errors.passphrase}
            onChange={formik.handleChange}
            fullWidth
            helperText={submitAttempted ? formik.errors.passphrase : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel>Expires in</InputLabel>
            <Select
              variant="outlined"
              name="expiryInMinutes"
              label="Expires in"
              value={formik.values.expiryInMinutes || ""}
              onChange={formik.handleChange}>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={240}>4 hours</MenuItem>
              <MenuItem value={480}>8 hours</MenuItem>
              <MenuItem value={1440}>24 hours</MenuItem>
            </Select>
            <FormHelperText>The secret will be purged and no longer available after this time</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              "& > *:first-child": {
                flexGrow: 1
              }
            }}>
            <div>
              <Button
                size="large"
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: (theme) => (theme.palette.mode === "light" ? theme.palette.primary.dark : theme.palette.primary.light),
                  color: (theme) => (theme.palette.mode === "light" ? theme.palette.primary.contrastText : theme.palette.offCommon.black)
                }}
                disabled={isSaving}>
                {isSaving ? "Generating..." : "Generate Link"}
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateSecretForm;
