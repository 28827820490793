import React from "react";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import useApi from "../api/ApiHook";
import { RevealSecretRequest, RevealSecretResponse } from "../api/models";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { AlertTitle } from "@mui/material";

const Secret = () => {
  const { id } = useParams<{ id: string }>();
  const { revealSecret, deleteSecret } = useApi();
  const [isFetching, setIsFetching] = React.useState(false);
  const [revealedSecret, setRevealedSecret] = React.useState<RevealSecretResponse | undefined>();
  const [deletedSecret, setDeletedSecret] = React.useState(false);

  const revealFormik = useFormik({
    initialValues: {
      passphrase: ""
    } as RevealSecretRequest,
    onSubmit: async (value) => {
      setIsFetching(true);
      try {
        const persistedSecret = id ? await revealSecret(id, value) : null;
        if (persistedSecret != null) {
          setRevealedSecret(persistedSecret);
        }
      } catch (err) {
        console.log(err);
      }
      setIsFetching(false);
    },
    validate: (value) => {
      const errors = {} as { [key in keyof RevealSecretRequest]: string };
      const requiredFields: (keyof RevealSecretRequest)[] = ["passphrase"];
      for (const field of requiredFields) {
        if (!value[field]) {
          errors[field] = "Required";
        }
      }
      return errors;
    }
  });

  const deleteFormik = useFormik({
    initialValues: {},
    onSubmit: async (value) => {
      setIsFetching(true);
      try {
        const deleteSecretResponse = id ? await deleteSecret(id) : null;
        if (deleteSecretResponse != null) {
          setDeletedSecret(true);
        }
      } catch (err) {
        console.log(err);
      }
      setIsFetching(false);
    }
  });

  const copySecretToClipboard = () => {
    if (!!revealedSecret) {
      navigator.clipboard.writeText(revealedSecret.secret);
    }
  };

  return (
    <>
      {revealedSecret && deletedSecret && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Nothing to see here!
            </Typography>
            <Alert severity="info" sx={{ mt: 5, mb: 2 }}>
              Secret no longer available!
            </Alert>
          </Grid>
        </Grid>
      )}
      {revealedSecret && !deletedSecret && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Your secret is:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                borderRadius: "4px",
                background: (theme) => (theme.palette.mode === "light" ? theme.palette.offCommon.white : theme.palette.grey[700]),
                pl: 1.5,
                pt: 0.5,
                pb: 0.5,
                mb: 1,
                display: "inline-block"
              }}>
              {revealedSecret.secret}{" "}
              <IconButton onClick={copySecretToClipboard} size="large">
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Keep it safe!
            </Typography>
            <Alert severity="info" sx={{ mt: 5, mb: 2 }}>
              <AlertTitle>This secret will be automatically deleted</AlertTitle>
              It will be no longer be available after {new Date(revealedSecret.expiryTime).toLocaleDateString()} at{" "}
              {new Date(revealedSecret.expiryTime).toLocaleTimeString()}
            </Alert>
            <form onSubmit={deleteFormik.handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      "& > *:first-child": {
                        flexGrow: 1
                      }
                    }}>
                    <div>
                      <Button
                        sx={{
                          borderColor: (theme) => theme.palette.error.main,
                          bgcolor: (theme) => theme.palette.error.main,
                          color: (theme) => theme.palette.error.contrastText,
                          "&:hover": {
                            bgcolor: (theme) => theme.palette.error.dark
                          }
                        }}
                        variant="contained"
                        type="submit"
                        disabled={isFetching}>
                        {isFetching ? "Deleting..." : "Burn It Now!"}
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      {!revealedSecret && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Reveal Your Secret
            </Typography>
            <form onSubmit={revealFormik.handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    name="passphrase"
                    label="Passphrase"
                    spellCheck={false}
                    value={revealFormik.values.passphrase}
                    error={!!revealFormik.errors.passphrase}
                    onChange={revealFormik.handleChange}
                    fullWidth
                    helperText={revealFormik.errors.passphrase}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      "& > *:first-child": {
                        flexGrow: 1
                      }
                    }}>
                    <div>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          bgcolor: (theme) => (theme.palette.mode === "light" ? theme.palette.primary.dark : theme.palette.primary.light),
                          color: (theme) => (theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.offCommon.black)
                        }}
                        disabled={!revealFormik.isValid || isFetching}>
                        {isFetching ? "Fetching..." : "Reveal"}
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Secret;
