import React from "react";
import { Divider, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import useApi from "../api/ApiHook";
import { PersistSecretRequest } from "../api/models";
import { Navigate } from "react-router-dom";
import AppExplanation from "./AppExplanation";
import CreateSecretForm from "./CreateSecretForm";

const Home = () => {
  const { persistSecret } = useApi();
  const [activeTab, setActiveTab] = React.useState<"secret" | "file">("secret");

  const [persistedSecretId, setPersistedSecretId] = React.useState<string | undefined>();

  const saveSecret = async (value: PersistSecretRequest) => {
    try {
      const persistedSecret = await persistSecret(value);
      if (persistedSecret != null) {
        setPersistedSecretId(persistedSecret.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: "secret" | "file") => {
    setActiveTab(newValue);
  };

  return persistedSecretId ? (
    <Navigate to={`/created/${persistedSecretId}`} />
  ) : (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 4 }}>
          <Tab label={"Encrypt a Secret"} value="secret" />
          <Tab label={"Share a File"} value="file" />
        </Tabs>
        {activeTab === "secret" && <CreateSecretForm saveSecret={saveSecret} />}
        {activeTab === "file" && (
          <div>
            <Typography gutterBottom>
              While SecretShare doesn't currently support sharing files directly within the app, there are some good secure options
              available:
            </Typography>
            <Typography variant="h6">Using OneDrive</Typography>
            <Typography>
              <ul>
                <li>
                  <Link
                    sx={{ color: ({ palette: { mode } }) => (mode === "light" ? "primary.dark" : "primary.light") }}
                    href="https://support.office.com/en-us/client/share-onedrive-files-and-folders-9fcc2f7d-de0c-4cec-93b0-a82024800c07">
                    Share a file or folder with someone
                  </Link>
                </li>
                <li>
                  <Link
                    sx={{ color: ({ palette: { mode } }) => (mode === "light" ? "primary.dark" : "primary.light") }}
                    href="https://support.microsoft.com/en-us/office/create-a-file-request-f54aa7f8-2589-4421-b351-d415fc3b83af">
                    Create a file request for someone to send you a file securely
                  </Link>
                </li>
              </ul>
            </Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AppExplanation />
      </Grid>
    </Grid>
  );
};
export default Home;
