import { Typography } from "@mui/material";

const AppExplanation = () => {
  return (
    <>
      <Typography variant="h4">About</Typography>
      <Typography variant="body1" gutterBottom>
        If you need to share a password, secret message or private link you should never send it through chat, Social Media or email. These
        methods are not secure and your data can potentially be compromised during transmission or accessed later in storage. Using
        SecretShare, you can safely and securely transfer this data to your recipient.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We care about security and we also want to make it simple for you to safely exchange secrets when necessary. That's why this app{" "}
        <strong>never</strong> stores any unencrypted secrets and no one that has access to the server or database could access the secret
        even if they wanted to.
      </Typography>
      <Typography variant="body1">
        Only someone who has both the link to a secret and the passphrase can view the decrypted version.
      </Typography>
      <br />
      <Typography variant="h6">How to use it:</Typography>
      <Typography variant="body2" component="div">
        <ul>
          <li>Enter the sensitive information you want to share in the Secret field.</li>
          <li>Choose a a word or phrase that is difficult to guess as Passphrase.</li>
          <li>Select an expiration period that determines how long the secret will be available for retrieval.</li>
          <li>Click the Generate Link button.</li>
          <li>
            A link will be generated and can now be publicly accessed. Share the link with the desired receipient(s) and make sure to
            provide the passphrase using a different channel.
          </li>
          <li>Remember, your secret will only be accessible to your recipient(s) for the designated period of time.</li>
        </ul>
      </Typography>
      <Typography variant="h6">How it works:</Typography>
      <Typography variant="body2" component="div">
        <ul>
          <li>All traffic from your browser to the server is encrypted.</li>
          <li>
            Your secret is encrypted with AES, using your passphrase and a random initialization vector (adding this value strengthens the
            encryption).
          </li>
          <li>
            We don't store your passphrase in the database, only the encrypted version. And since we don't have your passphrase we couldn't
            decrypt your secret even if we wanted to.
          </li>
          <li>Secrets are automatically purged from the database at the expiry time you choose.</li>
        </ul>
      </Typography>
    </>
  );
};

export default AppExplanation;
