import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Created = () => {
  const { id } = useParams<{ id: string }>();

  const href = window.location.href;
  const indexOf = href.indexOf("/created");

  const link = `${href.substr(0, indexOf)}/secured/${id}`;
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Share Your Secret
        </Typography>
        <Typography variant="body1" gutterBottom>
          Share the following link with the person you want to send the secret to:
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            borderRadius: "4px",
            background: (theme) => (theme.palette.mode === "light" ? theme.palette.offCommon.white : theme.palette.grey[700]),
            pl: 1.5,
            pt: 0.5,
            pb: 0.5,
            mb: 2,
            display: "inline-block",
            "& > a": {
              color: (theme) => (theme.palette.mode === "light" ? theme.palette.primary.dark : theme.palette.primary.light)
            }
          }}>
          <a href={link}>{link}</a>{" "}
          <IconButton
            sx={{ color: (theme) => (theme.palette.mode === "light" ? theme.palette.primary.dark : theme.palette.primary.light) }}
            onClick={copyLinkToClipboard}
            size="large">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Typography>
        <Alert severity="info">
          The secret can only be viewed by following the link and typing the passphrase you entered on the previous screen. Try to avoid
          sending the passphrase through the same channel that you send the link.
        </Alert>
      </Grid>
    </Grid>
  );
};

export default Created;
