import axios from "axios";
import { PersistSecretRequest, PersistSecretResponse, RevealSecretRequest, RevealSecretResponse } from "./models";

const useApi = () => {
  return {
    persistSecret: async (request: PersistSecretRequest): Promise<PersistSecretResponse | null> => {
      try {
        const response = await axios.post<PersistSecretResponse>("/secrets", request);

        if (response.status === 200) {
          return response.data;
        }
        return null;
      } catch (err) {
        return null;
      }
    },
    revealSecret: async (id: string, request: RevealSecretRequest): Promise<RevealSecretResponse | null> => {
      try {
        const response = await axios.post<RevealSecretResponse>(`/secrets/${id}`, request);

        if (response.status === 200) {
          return response.data;
        }
        return null;
      } catch (err) {
        return null;
      }
    },
    deleteSecret: async (id: string): Promise<boolean | null> => {
      try {
        const response = await axios.delete(`/secrets/${id}`);

        if (response.status === 200) {
          return true;
        }
        return null;
      } catch (err) {
        return null;
      }
    }
  };
};

export default useApi;
